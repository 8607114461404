import '../../../src/styles/styles.css';
import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks, faCheckCircle, faFrog, faTrash, faBullseye } from "@fortawesome/free-solid-svg-icons";
import { Card } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const ToDoItem = ({ index, toDo, deleteToDo, toggleToDoFocus, toggleToDoFrog, toggleTodoComplete }) => {
    const [showConfetti, setShowConfetti] = useState(false);
    const { width, height } = useWindowSize();
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        let confettiTimeout;
        let messageTimeout;

        if (toDo.complete && !showConfetti) {
            // Trigger confetti when todo is marked complete
            setShowConfetti(true);
            // Stop confetti after 3 seconds
            confettiTimeout = setTimeout(() => setShowConfetti(false), 3000);
            setShowMessage(true);
            messageTimeout = setTimeout(() => setShowMessage(false), 3000);
        }

        return () => {
            clearTimeout(confettiTimeout);
            clearTimeout(messageTimeout);
        };
    }, [toDo.complete]);

    return (
        <Draggable draggableId={toDo._id} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`shadow-sm dash-card-lg m-0 p-0
                        ${toDo.complete ? 'complete-item' : 'not-started-item'}`}
                >
                    {showConfetti && <Confetti width={width} height={height} />}

                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <div className="col-1">
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className={`p-1 ${toDo.complete ? 'text-success' : 'text-secondary'}`}
                                />
                            </div>
                            <div className="col-11 m-0 p-0">
                                <div className="row m-0 p-0">
                                    <div {...provided.dragHandleProps} className="p-0">
                                        <Card.Title className="text-dark mb-2">
                                            To-Do: {toDo.text || 'Default ToDo Value'}
                                        </Card.Title>
                                        {showMessage && (
                                            <div className="comic-message">
                                                <span>Great Work!</span>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 mb-2">
                                                <small className="text-muted py-1">
                                                    <div className='row m-0'>
                                                        <div className='col'>
                                                            Focus: <strong>{toDo.focus ? 'Yes' : 'No'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col'>
                                                            Difficult: <strong>{toDo.frog ? 'Yes' : 'No'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col'>
                                                            Complete: <strong>{toDo.complete ? 'Yes' : 'No'}</strong>
                                                        </div>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className="col-md-6 col-sm-12 d-flex align-items-center">
                                                <div className='row m-0'>
                                                    <div className='col d-flex justify-content-center'>

                                                        <div className={`dashboard-btn sq-btn`}
                                                            onClick={() => deleteToDo(toDo._id)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </div>
                                                        <div className={`dashboard-btn sq-btn me-1 ${toDo.focus ? 'btn-on' : ''}`}
                                                            onClick={() => toggleToDoFocus(toDo._id, !toDo.focus)}
                                                        >
                                                            <FontAwesomeIcon icon={faBullseye} />
                                                        </div>
                                                        <div className={`dashboard-btn sq-btn me-1 ${toDo.frog ? 'btn-on' : ''}`}
                                                            onClick={() => toggleToDoFrog(toDo._id, !toDo.frog)}
                                                        >
                                                            <FontAwesomeIcon icon={faFrog} />
                                                        </div>
                                                        <div className={`dashboard-btn sq-btn ${toDo.complete ? 'btn-complete' : ''}`}
                                                            onClick={() => toggleTodoComplete(toDo._id, !toDo.complete)}
                                                        >
                                                            <FontAwesomeIcon icon={faCheckCircle} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card >
            )
            }
        </Draggable >
    );
};

export default ToDoItem;
