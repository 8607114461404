import '../../src/styles/styles.css'; // The path should be relative to the current file

import { useState, useContext, useEffect } from "react";

// React-related imports
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";


// Component imports
import Navbar from "../components/page/Navbar";
import SearchItem from "../components/page/SearchItem";
import SideBar from "../components/page/SideBar";

// Context and API imports
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";

// Third-party library imports
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import '../styles/styles.css';

const List = ({ entity }) => {
    const { user } = useContext(AuthContext);
    const [capEntity, setCapEntity] = useState("");

    const [category, setCategory] = useState("All");

    const [customItem, setCustomItem] = useState("");
    const [customDescription, setCustomDescription] = useState("");
    const [customCategory, setCustomCategory] = useState("");


    const [allThings, setAllThings] = useState([]);
    const [userThings, setUserThings] = useState([]);
    const [loading, setLoading] = useState(false);

    const changeCategory = async (e) => {
        console.log("in changeCategory");
        console.log(e.target.value);

        setCategory(e.target.value);
    };

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const showToast = (message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            progressClassName: 'custom-toast-progress'
        });
    };

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );


    const checkIfLinked = (id) => {
        return !(userThings.indexOf(id) > -1)
    }

    const handleAdd = async (id) => {
        console.log("in handleAdd");

        try {

            // API call to add the item
            await api.post(`/api/user/add${entity}`, {
                user: user._id,
                [entity]: id
            });
        } catch (err) {
            console.error(err);
        }
    };

    // const handleRemove = async (id) => {
    //   try {
    //     setUserItem(prevState => !prevState);
    //     const res = await api.post(`/api/user/remove${entity}`,
    //       {
    //         user: user._id,
    //         [entity]: id
    //       }
    //     );
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };


    const handleCreateCustomItem = async () => {
        if (!customItem || !customCategory) {
            showToast(`Please enter a name and category for your custom ${capEntity}.`);
            return;
        }
        try {
            console.log("in handleCreateCustomItem");
            console.log("customItem", customItem);
            console.log("customDescription", customDescription);
            console.log("customCategory", customCategory);

            showToast(`${capEntity} created successfully!`);

            const res = await api.post(`/api/${entity}/createuser`, {
                text: customItem,
                description: customDescription,
                headline: customItem,
                category: customCategory,
                user: user._id,
            });
            setCustomItem("");
            setCustomDescription("");
            setCustomCategory("");
        } catch (err) {
            console.error(err);
        }
    };
    const retrieveAllThings = async () => {
        try {
            const res = await api.get(`/api/${entity}`);
            return res.data;
        } catch (err) {
        }
    }

    const retrieveCategoryThings = async () => {
        console.log("in retrieveCategoryThings");

        try {
            setLoading(true);
            let res = "";
            if (category === "All") {
                res = await api.get(`/api/${entity}`);
            }
            else {
                res = await api.get(`/api/${entity}/category/${category}`);
            }
            setLoading(false);
            return res.data;
        } catch (err) {
            setLoading(false);
        }
    }

    const retrieveUserThings = async () => {
        console.log("in retrieveCategoryThings");
        try {
            const res = await api.get(`/api/user/getuser${entity}s/${user._id}`);
            const userTempThings = res.data.map((item) => {
                if (entity === "goal") { return item.goal }
                if (entity === "habit") { return item.habit }
                if (entity === "locale") { return item.locale }
            });
            return userTempThings
        } catch (err) {
        }
    };

    useEffect(() => {
        console.log("in base useEffect");
        const getAllThings = async () => {
            const allThings = await retrieveAllThings();
            if (allThings) {
                setAllThings(allThings);
            }
        };
        const getUserThings = async () => {
            if (user) { // Check if user exists before fetching
                const allUserThings = await retrieveUserThings();
                if (allUserThings) {
                    setUserThings(allUserThings);
                }
            }
        };
        getAllThings();
        getUserThings(); // Call getUserThings directly
    }, [user]); // Add user as a dependency

    useEffect(() => {
        console.log("in entity change useEffect");

        setCapEntity(capitalizeFirstLetter(entity));
        setCustomItem("");
        setCustomDescription("");
        setCustomCategory("");
        setCategory("All");

        const getAllThings = async () => {
            const allThings = await retrieveAllThings();
            if (allThings) {
                setAllThings(allThings);
            }
        };
        getAllThings();
    }, [entity]); // Only fetch all things when entity changes

    useEffect(() => {
        console.log("in category change useEffect");

        const getCategoryThings = async () => {
            const categoryThings = await retrieveCategoryThings();
            if (categoryThings) {
                setAllThings(categoryThings);
            }
        };
        getCategoryThings();
    }, [category]);

    return (
        <div className="container-fluid">
            <Helmet>
                {entity === "goal" ?
                    <title>Choose Goals to improve your life</title>
                    :
                    <title>Choose Habits to achieve your goals</title>
                }
                <meta name="description" content="Select from a preconfigured list of Goals and Habits" />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                        <div className="col my-auto">
                            <div className="input-group my-auto">
                                <div className="input-group-prepend my-auto">
                                    <label className="input-group-text secondary-btn" htmlFor="inputGroupSelect01">Categories</label>                                </div>
                                <select label="Category"
                                    onChange={(e) => changeCategory(e)}
                                    value={category}>
                                    <option value="All">All</option>
                                    <option value="Career">Career</option>
                                    <option value="Development">Development</option>
                                    <option value="Family">Family</option>
                                    <option value="Finance">Finance</option>
                                    <option value="Fun">Fun</option>
                                    <option value="Health">Health</option>
                                    <option value="Relationship">Relationship</option>
                                    <option value="Spiritual">Spiritual</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col p-1 m-0 d-flex flex-wrap">
                        {loading ? (
                            <div className="row d-flex justify-content-center  align-items-center p-3 m-0">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="col-md-4 col-12 py-2">
                                    <div className="card shadow m-1 p-0 h-100">
                                        <div className="card-body d-flex flex-column justify-content-between">
                                            <div className="">
                                                <p><strong>Create Your Own {capEntity}</strong></p>
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="item-name" className="mb-1"><strong>{capEntity} Name</strong></label>
                                                <input type="text"
                                                    className="form-control p-1"
                                                    id="item-name"
                                                    placeholder="Enter name"
                                                    value={customItem} onChange={(e) => setCustomItem(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="item-description" className="mb-1"><strong>{capEntity} Description</strong></label>
                                                <textarea className="form-control"
                                                    id="item-description"
                                                    placeholder="Enter description"
                                                    value={customDescription} onChange={(e) => setCustomDescription(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group mb-4">
                                                <label htmlFor="item-category" className="mb-1"><strong>{capEntity} Category</strong></label>
                                                <select className="form-control" id="item-category" value={customCategory} onChange={(e) => setCustomCategory(e.target.value)}>
                                                    <option value="">Select a category</option>
                                                    <option value="Career">Career</option>
                                                    <option value="Development">Development</option>
                                                    <option value="Family">Family</option>
                                                    <option value="Finance">Finance</option>
                                                    <option value="Fun">Fun</option>
                                                    <option value="Health">Health</option>
                                                    <option value="Relationship">Relationship</option>
                                                    <option value="Spiritual">Spiritual</option>
                                                </select>
                                            </div>
                                            <div className="text-center">

                                                {user ? (
                                                    <div
                                                        className="secondary-btn p-1 w-50"
                                                        onClick={handleCreateCustomItem}><strong>Create {capEntity}</strong>
                                                    </div>
                                                ) : (
                                                    <Link to="/login" className="btn secondary-btn p-1 m-0 w-50">
                                                        Login to create custom {capitalizeFirstLetter(entity)}
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {allThings &&
                                    allThings.map((item) => (
                                        <SearchItem
                                            key={item._id}
                                            item={item}
                                            linked={checkIfLinked(item._id)}
                                            user={user}
                                            entity={entity}
                                            loading={loading}
                                            handleAdd={() => handleAdd(item._id)}
                                            toastMessage={`${entity.charAt(0).toUpperCase() + entity.slice(1)} added successfully!`}
                                            toastIcon={faCheck}
                                        />
                                    ))
                                }
                            </>
                        )}
                    </div>
                </div >
            </div >
        </div >

    );
};

export default List;
