import React from "react";
import HabitSummaryChart from './HabitSummaryChart.jsx';

const HabitStatusWidget = ({ habitStats, totalHabits }) => {
    const content = totalHabits === 0 ? (
        <div className="text-center p-3">
            <p>No habits yet! Ready to set some?</p>
            <a href="/habits" className="btn secondary-btn">Go to Habits Page</a>
        </div>
    ) : (
        <>
            <HabitSummaryChart className="h-100" habitStats={habitStats} />
        </>
    );

    console.log("habitStats", JSON.stringify(habitStats));
    return (
        <div className="col-md-4 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold secondary-color">
                    Habit Status
                </div>
                <div className="flex-grow-1">
                    {content}
                </div>
                <div className="p-2 secondary-color">
                    {totalHabits} Daily Habits
                </div>
            </div>
        </div>
    );
};

export default HabitStatusWidget;


// const prepareChartData = () => {
//     const chartData = [];
//     Object.keys(habitStats).forEach((habitId) => {
//         const habitData = habitStats[habitId];
//         chartData.push({
//             dateFormatted: 'Today', // or any other date formatting
//             Complete: habitData.week,
//         });
//     });
//     return chartData;
// };


// const prepareChartData = () => {
//     // Assuming 'daysOfWork' is an array of days, each containing an array of habits
//     // Example format of daysOfWork: [{ date: '2023-03-01', habits: [{ name: 'Habit1', complete: true }, { name: 'Habit2', complete: false }] }, ...]

//     const chartData = habitStats.map(day => {
//         const dateFormatted = new Date(day.date).toLocaleDateString();
//         const dayStats = {
//             date: dateFormatted,  // Use date as key
//             week: 0,
//             month: 0,
//             year: 0,
//             inception: 0
//         };

//         // Calculate stats for each habit
//         day.habits.forEach(habit => {
//             // Increment the stats based on some condition, example given below
//             // You can adjust these conditions based on your actual logic
//             dayStats.week += habit.complete ? 1 : 0;
//             dayStats.month += habit.complete ? 1 : 0;
//             dayStats.year += habit.complete ? 1 : 0;
//             dayStats.inception += habit.complete ? 1 : 0;
//         });

//         return dayStats;
//     });

//     return chartData;
// };