import Header from "../components/page/Header";
import Navbar from "../components/page/Navbar";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { api } from "../api/api.js";

const Success = () => {
    const { user, dispatch } = useContext(AuthContext);

    useEffect(() => {
        const updatePaymentStatus = async () => {
            try {
                // Update payment status in backend
                console.log("here1");
                await api.put(`/api/user/updatepaymentstatus`, { userId: user._id });
                console.log("here2");

                // Fetch updated user data
                const res = await api.get(`/api/user/find/${user._id}`);
                console.log("here3");
                // Dispatch the updated user data
                dispatch({ type: "LOGIN_PASS", payload: res.data });
            } catch (error) {
                console.error('Error updating payment status:', error);
            }
        };

        if (user._id) {
            updatePaymentStatus();
        }
    }, [user._id, dispatch]);

    return (
        <div>
            <div className="container-fluid p-0 m-0">
                <Navbar />
                <Header />
                <div className="container py-5">
                    <div>
                        <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                            <div className="col mx-auto">
                                <div className="card mb-4 rounded-3 shadow-sm h-100">
                                    <div className="card-header py-3">
                                        <h4 className="my-0 fw-normal">Success</h4>
                                    </div>
                                    <div className="card-body">
                                        Payment successful!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;
