import '../../../src/styles/styles.css';
import React from 'react';

const EditModal = ({ show, onClose, onSave, entity, onChange }) => {
    if (!show) {
        return null;
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div className="modal show" tabIndex="-1" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit {capitalizeFirstLetter(entity.type)}</h5>
                        <button className="dashboard-btn sq-btn" onClick={onClose}>
                            X
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            type="text"
                            className="form-control"
                            value={entity.value}
                            onChange={onChange}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
                        <button type="button" className="btn primary-color text-black" onClick={onSave}>Save changes</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditModal;
