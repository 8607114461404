import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import Footer from "../components/page/Footer";
import { Link } from "react-router-dom";
import { golApp } from "../urls"




const NotFound = () => {
    return (
        <div className="container-fluid p-0 m-0">
            <Helmet>
                <title>Empower Your Life with Goals & Habits Management</title>
                <meta name="description" content="Discover a comprehensive suite of tools designed to propel you towards a more fulfilling life. Define empowering goals, track your progress, cultivate productive habits, and more with Goals & Habits. Start your journey today." />
            </Helmet>
            <Navbar />
            <Header />
            <div className="h-100 d-flex flex-column text-center justify-content-center align-items-center">
                <h1 className="display-4 p-5">404 - Page Not Found</h1>
                <p className="lead ">The page you're looking for could not be found.</p>
                <a className="p-5" href={golApp} >Return Home</a>

            </div>
            <Footer />
        </div>

    );
}

export default NotFound;