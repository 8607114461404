import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek, faCalendarAlt, faCalendar } from "@fortawesome/free-solid-svg-icons";

const HabitSummaryChart = ({ habitStats }) => {
    // console.log("rawdata", rawData);
    const [timeFrame, setTimeFrame] = useState("week");
    const [filteredData, setFilteredData] = useState([]);
    const filterData = (timeFrame) => {
        try {
            const currentDate = new Date();
            const timeFrames = {
                week: 7 * 24 * 60 * 60 * 1000,
                month: 30 * 24 * 60 * 60 * 1000,
                year: 365 * 24 * 60 * 60 * 1000,
            };

            if (!habitStats || !Array.isArray(habitStats) || habitStats.length === 0) {
                return [];
            }

            const filtered = habitStats.filter(habitData => {
                const timeDiff = currentDate - new Date(habitData.itemDate);
                return timeDiff <= timeFrames[timeFrame];
            });

            // If the filtered data is empty, fallback to all available data
            if (filtered.length === 0) {
                return habitStats;
            }

            // If there's only one date, add a previous day with 0
            if (filtered.length === 1) {
                const previousDate = new Date(filtered[0].date);
                previousDate.setDate(previousDate.getDate() - 1);
                filtered.unshift({
                    date: previousDate.toISOString(),
                    Complete: 0,
                    Inception: 0,
                    Month: 0,
                    Year: 0,
                });
            }

            return filtered;
        } catch (error) {
            console.log("Error in HabitSummaryChart:filterData: " + error);
            return [];
        }
    };

    useEffect(() => {
        setFilteredData(filterData(timeFrame));
    }, [timeFrame, habitStats]);

    return (
        <div className="chart-container m-0 p-0">
            <div className="button-group d-flex justify-content-end m-0">
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "week" ? "active" : ""}`}
                    onClick={() => setTimeFrame("week")}
                >
                    <FontAwesomeIcon icon={faCalendarWeek} />
                </button>
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "month" ? "active" : ""}`}
                    onClick={() => setTimeFrame("month")}
                >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </button>
                <button
                    className={`btn btn-link chart-button p-0 mx-1 ${timeFrame === "year" ? "active" : ""}`}
                    onClick={() => setTimeFrame("year")}
                >
                    <FontAwesomeIcon icon={faCalendar} />
                </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={filteredData} margin={{ top: 5, right: 0, left: -20, bottom: 0 }} // Adjust left margin here
                >

                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dateFormatted" tick={{ fontSize: 12 }} />
                    <YAxis
                        tick={{ fontSize: 12 }}
                        allowDecimals={false} // This ensures only whole numbers are displayed

                    />
                    <Tooltip />
                    <Legend />
                    <Area type="monotone" dataKey="Complete" stroke="#277da1" fill="#f9c74f" />
                </AreaChart>
            </ResponsiveContainer>

        </div>
    );
};

export default HabitSummaryChart;