import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";

const ResetEmail = () => {
  const [info, setInfo] = useState({ email: "" });
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const handleChange = (e) => {
    setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleResetEmail = async (e) => {
    e.preventDefault();

    try {
      setInfo({ email: "" });
      const res = await api.post(`/api/auth/resetemail`, { ...info });

      if (res.status === 200) {
        setMessage("Reset email sent. Check your email for reset link");
        setErrorMessage(undefined);
      }
    } catch (err) {
      if (!err?.response) {
        setErrorMessage("No Server Response");
        setMessage(undefined);
      } else if (err.response?.status === 401) {
        setErrorMessage("User not found");
        setMessage(undefined);
      } else if (err.response?.status === 422) {
        setErrorMessage("Reset email already sent. Check your email for reset link");
        setMessage(undefined);
      } else {
        setErrorMessage("Login Failed");
        setMessage(undefined);
      }
    }
  };

  return (
    <div className="container-fluid p-0 m-0 vh-100">
      <Helmet>
        <title>Reset Email - Goals & Habits Management"</title>
        <meta name="description" content="Reset your email with Goals & Habits Management. Take control of your account's information and continue your journey towards a more fulfilling life. Get started now!" />
      </Helmet>
      <Navbar />
      <div className="container-fluid m-0 p-0 h-75">
        <div className="row d-flex justify-content-center align-items-center p-3 m-0 h-100">
          <div className="card rounded-3 shadow-sm col-lg-4 col-md-8 col-sm-10 border-primary p-0 m-0">
            <div className="card-header py-2">
              <h4 className="m-0 fw-normal text-center">Password Reset</h4>
            </div>
            {errorMessage && (
              <div className="row p-0 m-0">
                <div className="m-2 p-2 text-danger text-center">{errorMessage}</div>
              </div>
            )}
            {message && (
              <div className="row p-0 m-0">
                <div className="m-2 p-2 text-success text-center">{message}</div>
              </div>
            )}
            <form className="card-body px-4 m-0">
              <div className="p-0 m-0 form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="text"
                  placeholder="Enter email"
                  onChange={handleChange}
                  className="form-control border-primary"
                  value={info.email}
                />
              </div>
              <div className="d-flex justify-content-center p-3">
                <div onClick={handleResetEmail} className="btn btn-primary w-25">
                  <p className="d-flex justify-content-center my-auto">Submit</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetEmail;
