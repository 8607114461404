import { useState } from "react";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ToDoItem from './ToDoItem';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";


const ToDoWidget = ({ activeToDos, totalActiveToDos, updateToDoOrder, addToDo, deleteToDo, toggleToDoComplete, toggleToDoFocus, toggleToDoFrog }) => {

    const onDragEnd = async (dragResult) => {
        const { source, destination } = dragResult;
        if (!destination || source.index === destination.index) return;
        const reorderedToDos = Array.from(activeToDos);
        const [reorderedToDo] = reorderedToDos.splice(source.index, 1);
        reorderedToDos.splice(destination.index, 0, reorderedToDo);
        updateToDoOrder(reorderedToDos);
    };

    const [toDoText, setToDoText] = useState("");
    const handleAddToDo = async (e) => {
        console.log("in handleAddToDo");
        console.log("toDoText", toDoText);
        e.preventDefault();
        if (!toDoText.trim()) return;
        await addToDo(toDoText);
        setToDoText("");
    };

    return (
        <div className="col-md-8 col-sm-12 mb-3">
            <div className="shadow h-100 d-flex flex-column">
                <div className="p-2 fw-bold primary-color">
                    To Dos
                </div>
                <div className="flex-grow-1">
                    <div className="row py-2">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <form onSubmit={handleAddToDo} className="">
                                <div className="input-group">
                                    <input
                                        className="form-control"
                                        required
                                        type="text"
                                        placeholder="Add new to-do item..."
                                        value={toDoText}
                                        onChange={(e) => setToDoText(e.target.value)}
                                        style={{ borderRadius: '5px' }}
                                    />
                                    <button type="submit" className="btn btn-link text-success border p-1 m-1">
                                        <FontAwesomeIcon icon={faCirclePlus} />
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row justify-content-center flex-grow-1">
                        <div className="col px-3">
                            {activeToDos.length === 0 ? (
                                <div className="text-center p-3">
                                    <p>No Active Todos! Add some above</p>
                                </div>
                            ) : (
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="todos">
                                        {provided => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} className="d-flex flex-wrap">
                                                {activeToDos.map((toDo, index) => (
                                                    <div key={toDo._id} className="col-md-6 col-sm-12 p-1">
                                                        <ToDoItem
                                                            index={index}
                                                            toDo={toDo}
                                                            deleteToDo={deleteToDo}
                                                            toggleToDoFocus={toggleToDoFocus}
                                                            toggleToDoFrog={toggleToDoFrog}
                                                            toggleTodoComplete={toggleToDoComplete}
                                                        />
                                                    </div>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}
                        </div>
                    </div>
                </div>
                <div className="p-2 mt-auto primary-color">
                    {totalActiveToDos} Active To-Dos
                </div>
            </div>
        </div >
    );
};

export default ToDoWidget;
