import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import MailList from "../components/page/MailList";
import Footer from "../components/page/Footer";
import SideBar from "../components/page/SideBar";
import FeaturedItems from "../components/page/FeaturedItems";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChartGantt,
    faHandPointer,
    faFrog,
    faCheck,
    faBullseye,
    faGear,
} from "@fortawesome/free-solid-svg-icons";
import '../../src/styles/styles.css';


const Home = () => {
    const featureItems = [
        {
            icon: faHandPointer,
            text: "Define Empowering Goals:",
            text2: "Choose goals that will propel you towards a more fulfilling life. These can range from personal achievements to professional milestones, all tailored to your unique aspirations.",
        },
        {
            icon: faChartGantt,
            text: "Track Your Progress",
            text2: "Monitor your journey towards achieving those important milestones. Visualizing your progress is a powerful motivator, and Goals & Habits provides clear, intuitive tracking tools.",
        },
        {
            icon: faHandPointer,
            text: "Cultivate Productive Habits:",
            text2: "Select habits that align with your goals for maximum impact. Whether it's establishing a morning routine or dedicating time to a passion project, building positive habits is key to sustained success.",
        },
        {
            icon: faChartGantt,
            text: "Habit Trends Analysis:",
            text2: "Gain insights into your habits' performance over time. By understanding how your habits evolve, you can make informed adjustments for even greater effectiveness.",
        },
        {
            icon: faChartGantt,
            text: "Daily Habit Tracking:",
            text2: "Keep tabs on your daily habits to ensure consistent progress. Through simple, user-friendly interfaces, you can easily log your activities and see patterns emerge.",
        },
        {
            icon: faGear,
            text: "Holistic Productivity Tracking:",
            text2: "Get an overview of your overall productivity levels. Goals & Habits doesn't just focus on specific tasks—it provides a comprehensive view of how you're utilizing your time and energy.",
        },
        {
            icon: faCheck,
            text: "Stay Organized:",
            text2: "Keep loose tasks in check by creating to-dos within the app. Whether it's a simple reminder or a detailed checklist, you can ensure nothing falls through the cracks.",
        },
        {
            icon: faBullseye,
            text: "Focus on Priorities:",
            text2: "Mark items as your current focal points to stay on track. With the ability to designate high-priority tasks, you can allocate your resources where they matter most.",
        },
        {
            icon: faFrog,
            text: "Face Challenges Head-On:",
            text2: "Identify and tackle particularly challenging tasks. By acknowledging which items require extra effort, you can approach them with confidence and determination.",
        },
    ];

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Empower Your Life with Goals & Habits Management</title>
                <meta name="description" content="Discover a comprehensive suite of tools designed to propel you towards a more fulfilling life. Define empowering goals, track your progress, cultivate productive habits, and more with Goals & Habits. Start your journey today." />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row">
                        <div className="col">
                            <Header />
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                        <div className="col d-flex align-items-center">
                            <h3 className="mb-0">How can we help?</h3>
                        </div>
                    </div>
                    <div className="row">
                        {featureItems.map((item, index) => (
                            <div key={index} className="col-lg-4 col-md-6 mb-3">
                                <div className="card border border-dark h-100 bg-light">
                                    <div className="card-body">
                                        <p className="card-text">
                                            <strong>{item.text}</strong>
                                        </p>
                                        <p className="card-text">
                                            {item.text2}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <MailList /> */}
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Home;



{/* <div className="row">
                        <div>
                            <div className="col my-4 mx-auto">
                                <p className="h4 text-dark m-3">Sample Goals</p>
                                <FeaturedItems entity="goal" />
                            </div>
                            <div className="col my-4 mx-auto">
                                <p className="h4 text-dark m-3">Sample Habits</p>
                                <FeaturedItems entity="habit" />
                            </div>
                        </div>
                    </div> */}
