import { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import { api } from "../api/api.js"
import { AuthContext } from "../context/AuthContext";

const ResetPassword = () => {
  const hash = useParams();
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const { loading } = useContext(AuthContext);

  const handleChange = (e) => {
    setPassword((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const req = {
      hash: hash,
      password: password
    }
    try {
      setPassword("");
      const res = await api.post(`/api/auth/resetpassword`, req);

      if (res.status === 200) {
        setMessage("Password Reset");
        setErrorMessage(undefined);
      }
    } catch (err) {
      console.log(err);
      console.log(err.status);
      if (!err?.response) {
        console.log("No server response");
        setErrorMessage("No Server Response");
        setMessage(undefined);
      } else if (err.response?.status === 404) {
        console.log("Activation link has expired");
        setErrorMessage("Activation link has expired");
        setMessage(undefined);
      } else {
        console.log("Reset Failed");
        setErrorMessage("Reset Failed");
        setMessage(undefined);
      }
    }
  };

  return (
    <div className="container-fluid p-0 m-0 vh-100">
      <Helmet>
        <title>Reset Password - Goals & Habits Managemen</title>
        <meta name="description" content="Reset your password with Goals & Habits Management. Take control of your account's security and continue your journey towards a more fulfilling life. Get started now!" />
      </Helmet>
      <Navbar />
      <div className="container-fluid m-0 p-0 h-75">
        <div className="row d-flex justify-content-center align-items-center p-3 m-0 h-100">
          <div className="card rounded-3 shadow-sm col-lg-4 col-md-8 col-sm-10 border-primary p-0 m-0">
            <div className="card-header py-2">
              <h4 className="m-0 fw-normal text-center">Reset Password</h4>
            </div>
            {errorMessage &&
              <>
                <div className="row p-0 m-0">
                  <div className="m-2 p-2 text-success text-center">
                    {errorMessage}
                  </div>
                </div>
              </>
            }
            {message &&
              <>
                <div className="row p-0 m-0">
                  <div className="m-0 p-2 text-success text-center">
                    {message}
                  </div>
                  <div className="m-0 p-2 text-success text-center">
                    <p className="m-0 p-0">Click <Link to="/login">here</Link> to login.</p>
                  </div>
                </div>
              </>
            }
            <form className="card-body px-4 m-0">
              <div className="p-0 my-3 form-group">
                <label htmlFor="email">New Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  id="password"
                  onChange={handleChange}
                  className="form-control border-primary"
                />
              </div>
              <div className="d-flex justify-content-center p-3">
                <div disabled={loading} onClick={handlePasswordChange} className="btn btn-primary w-25">
                  <p className="d-flex justify-content-center my-auto">Reset</p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;