import '../src/styles/styles.css'; // The path should be relative to the current file

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from "@react-oauth/google";


// import Account from "./pages/Account";
import Activate from "./pages/Activate";
import AIPlan from "./pages/AIPlan";
import Cancel from "./pages/Cancel";
import Home from "./pages/Home";
import Instructions from "./pages/Instructions";
import List from "./pages/List";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Plans from "./pages/Plans";
import Register from "./pages/Register";
import ResetEmail from "./pages/ResetEmail";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import Success from "./pages/Success";
import Test from "./pages/Test";
import Workspace from "./pages/Workspace";

function App() {
    useEffect(() => {
        const LAST_VISITED_KEY = 'lastVisited';
        const lastVisited = new Date(localStorage.getItem(LAST_VISITED_KEY));
        const today = new Date();

        // Check if the app is accessed for the first time or the last access date was before today
        if (!lastVisited || lastVisited.toDateString() !== today.toDateString()) {
            localStorage.setItem(LAST_VISITED_KEY, today.toString());
            window.location.reload(); // Refresh the page
        }
    }, []);

    // useEffect(() => {
    //   const handleVisibilityChange = () => {
    //     if (!document.hidden) {
    //       window.location.reload();
    //     }
    //   };
    //   document.addEventListener("visibilitychange", handleVisibilityChange, false);
    //   return () => {
    //     document.removeEventListener("visibilitychange", handleVisibilityChange, false);
    //   };
    // }, []);

    return (
        <>
            <GoogleOAuthProvider clientId="411046985952-6id3uvrs3jb2fdc6gmh02icaaqubbsdc.apps.googleusercontent.com">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        {/* <Route path="/account" element={<Account />} /> */}
                        <Route path="/activate/:hash" element={<Activate />} />
                        <Route path="/ai" element={<AIPlan />} />
                        <Route path="/cancel" element={<Cancel />} />
                        <Route path="/goals" element={<List entity="goal" />} />
                        <Route path="/habits" element={<List entity="habit" />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/instructions" element={<Instructions />} />
                        <Route path="/plans" element={<Plans />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/resetemail" element={<ResetEmail />} />
                        <Route path="/resetpassword/:hash" element={<ResetPassword />} />
                        <Route path="/success" element={<Success />} />
                        <Route path="/test" element={<Test entity="goal" />} />
                        <Route path="/workspace" element={<Workspace />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
                <ToastContainer />
            </GoogleOAuthProvider>
        </>

    );
}


export default App;
