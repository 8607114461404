
import Header from "../components/page/Header";
import Navbar from "../components/page/Navbar";
import { Helmet } from "react-helmet";


const Cancel = () => {

    return (
        <div>
            <div className="container-fluid  p-0 m-0">
                <Helmet>
                    <title>Cancel Subscription - Goals & Habits Management</title>
                    <meta name="description" content="Cancel your subscription with Goals & Habits Management. Follow the steps to end your membership and explore other options for your journey towards a more fulfilling life." />
                </Helmet>
                Cancel Subscription - Goals & Habits Management"


                <Navbar />
                <Header />
                <div className="container py-5">
                    <div>
                        <div className="row row-cols-1 row-cols-md-2 mb-3 text-center">
                            <div className="col mx-auto">
                                <div className="card mb-4 rounded-3 shadow-sm h-100">
                                    <div className="card-header py-3">
                                        <h4 className="my-0 fw-normal">Cancel</h4>
                                    </div>
                                    <div className="card-body">
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default Cancel;
