import '../../../src/styles/styles.css';
import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faCheckCircle, faChartLine, faFrog, faTrash, faBullseye } from "@fortawesome/free-solid-svg-icons";
import { Card, Form, Collapse } from 'react-bootstrap';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';

const HabitItem = ({
    index,
    user2habit,
    removeHabit,
    toggleHabitComplete,
    toggleHabitFocus,
    toggleHabitFrog,
    updateHabitStart,
    updateHabitEnd,
    updateHabitDetail,
    habitDay,
    isEditable
}) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [habitDetail, setHabitDetail] = useState(user2habit?.detail || "");
    const [habitStart, setHabitStart] = useState(user2habit?.start || "");
    const [habitEnd, setHabitEnd] = useState(user2habit?.end || "");
    const [habitComplete, setHabitComplete] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const { width, height } = useWindowSize();
    const [showMessage, setShowMessage] = useState(false);


    const toggleCollapse = () => setIsCollapsed(!isCollapsed);

    useEffect(() => {
        const isHabitComplete = user2habit?.completionHistory.some(history => {
            const historyDate = new Date(history.date).toLocaleDateString();
            const currentDate = habitDay.toLocaleDateString();
            return historyDate === currentDate && history.completed;
        });

        if (isHabitComplete && !habitComplete) {
            // Trigger confetti when habit is marked complete
            setShowConfetti(true);
            // Stop confetti after 3 seconds
            setTimeout(() => setShowConfetti(false), 3000);
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 3000);
        }

        setHabitComplete(isHabitComplete);
    }, [user2habit, habitDay, habitComplete]);

    return (
        <Draggable draggableId={user2habit._id} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`shadow-sm dash-card-lg
                        ${habitComplete ? 'complete-item' : 'not-started-item'}`}
                >
                    {showConfetti && <Confetti width={width} height={height} />}

                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <div className="col-1">
                                <FontAwesomeIcon
                                    icon={faChartLine}
                                    className={`p-1 ${habitComplete ? 'text-success' : 'text-secondary'}`}
                                />
                            </div>
                            <div className="col-11 m-0 p-0">
                                <div className="row m-0 p-0">
                                    <div {...provided.dragHandleProps} className="p-0">
                                        <Card.Title className="text-dark mb-2">
                                            Habit: {user2habit.habit?.text || "Default Habit Value"}
                                        </Card.Title>
                                        {showMessage && (
                                            <div className="comic-message">
                                                <span>Great Work!</span>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12 mb-2">
                                                <small className="text-muted">
                                                    <div className='row m-0'>
                                                        <div className='col'>
                                                            Focus: <strong>{user2habit.focus ? 'Yes' : 'No'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col'>
                                                            Difficult: <strong>{user2habit.frog ? 'Yes' : 'No'}</strong>
                                                        </div>
                                                    </div>
                                                    <div className='row m-0'>
                                                        <div className='col'>
                                                            Complete: <strong>{habitComplete ? 'Yes' : 'No'}</strong>
                                                        </div>
                                                    </div>
                                                </small>
                                            </div>
                                            <div className="col-md-6 col-sm-12 d-flex align-items-center">
                                                <div className='row m-0'>
                                                    <div className='col d-flex justify-content-center'>
                                                        <div className="dashboard-btn sq-btn me-1"
                                                            onClick={toggleCollapse}
                                                            aria-expanded={!isCollapsed}
                                                            aria-controls={`collapseHabit${user2habit._id}`}
                                                        >
                                                            <FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp} />
                                                        </div>
                                                        <div className="dashboard-btn sq-btn me-1"
                                                            onClick={() => removeHabit(user2habit._id)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </div>
                                                        <div className={`dashboard-btn sq-btn me-1 ${user2habit.focus ? 'btn-on' : ''}`}
                                                            onClick={() => toggleHabitFocus(user2habit._id, !user2habit.focus)}
                                                        >
                                                            <FontAwesomeIcon icon={faBullseye} />
                                                        </div>
                                                        <div className={`dashboard-btn sq-btn me-1 ${user2habit.frog ? 'btn-on' : ''}`}
                                                            onClick={() => toggleHabitFrog(user2habit._id, !user2habit.frog)}
                                                        >
                                                            <FontAwesomeIcon icon={faFrog} />
                                                        </div>
                                                        <div className={`dashboard-btn sq-btn ${habitComplete ? 'btn-complete' : ''}`}
                                                            onClick={() => toggleHabitComplete(user2habit._id, habitDay, !habitComplete)}
                                                            disabled={!isEditable(habitDay)}
                                                        >
                                                            <FontAwesomeIcon icon={faCheckCircle} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={!isCollapsed}>
                                            <div id={`collapseHabit${user2habit._id}`} className="mt-3">
                                                <Form onSubmit={(e) => e.preventDefault()}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Habit Detail:</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            value={habitDetail}
                                                            onChange={(e) => setHabitDetail(e.target.value)}
                                                            onBlur={() => updateHabitDetail(user2habit._id, habitDetail)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Daily Start Time:</Form.Label>
                                                        <Form.Control
                                                            type="time"
                                                            value={habitStart}
                                                            onChange={(e) => setHabitStart(e.target.value)}
                                                            onBlur={() => updateHabitStart(user2habit._id, habitStart)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Daily End Time:</Form.Label>
                                                        <Form.Control
                                                            type="time"
                                                            value={habitEnd}
                                                            onChange={(e) => setHabitEnd(e.target.value)}
                                                            onBlur={() => updateHabitEnd(user2habit._id, habitEnd)}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )
            }
        </Draggable >
    );
};

export default HabitItem;
